.close_icon {
    position: absolute;
    right: 7px;
    top: 7px;
    font-size: 12px;
    cursor: pointer;
}

.share-in-wall-title {
    font-family: "Pompiere";
    color: #4011c6;
    font-weight: 600;
    font-size: 20px;
}

.form-section {
    font-family: "Poppins" !important;
}

.share-inputs {
    font-family: "Poppins" !important;
}



.user-name-for-popup {
    margin: 0;
}

.screenshot-preview-div {
    display: flex;
    align-items: center;
    justify-content: center;
    border: 1px solid #CFD6E4;
    border-radius: 8px;
    padding: 10px;
    background-color: #CFD6E4;
}

.share-button-for-popup {
    color: white;
    background: linear-gradient(180deg, #4011C6 0%, #3D12B7 100%);
    border: none;
    border-radius: 16px;
    display: flex;
    align-items: center;
    padding: 5px 5px 5px 13px;
    font-family: 'Poppins';
    cursor: pointer;
}

.cancel-button-for-popup {
    color: #4011C6;
    background-color: white;
    align-items: center;
    padding: 5px 5px 5px 13px;
    font-family: 'Poppins';
    border: none !important;
    cursor: pointer;
    font-weight: 500;
    margin-right: 10px;
}

.success-message-for-wall-post {
    text-align: center;
}

.your-screenshort-text {
    font-family: "Poppins" !important;
    color: #000000;
    font-size: 20px;
}

.share-popup-footer-div {
    display: flex;
    justify-content: end;
    align-items: center;
    margin-top: 20px;
}

.user-note {
    font-family: "Poppins" !important;
    font-size: 10px;
    margin-bottom: 0px;
    margin-top: 0px;
    color: #637381
}

.enter-title-text {
    font-family: "Poppins" !important;
    font-size: 13px !important;
}