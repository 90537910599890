@import "open-color/open-color.scss";

.visually-hidden {
  position: absolute !important;
  height: 1px;
  width: 1px;
  overflow: hidden;
  clip: rect(1px, 1px, 1px, 1px);
  white-space: nowrap;
  /* added line */
  user-select: none;
}

.LoadingMessage {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 999;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  pointer-events: none;

  .Spinner {
    font-size: 2.8em;
  }

  .LoadingMessage-text {
    margin-top: 1em;
    font-size: 0.8em;
  }
}

.LoadingMessage--dark {
  background-color: #121212;
  color: #ced4da;
}

@font-face {
  font-family: Poppins;
  src: url("../assets/fonts/Poppins/Poppins-Regular.ttf");

}

@font-face {
  font-family: Pompiere;
  src: url("../assets/fonts/Pompiere/Pompiere-Regular.ttf");
}