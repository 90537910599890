.footer-center {
  pointer-events: none;

  &>* {
    pointer-events: all;
  }

  display: flex;
  width: 100%;
  justify-content: flex-start;
}

.share-button {
  color: white;
  background: linear-gradient(180deg, #4011C6 0%, #3D12B7 100%);
  border: none;
  border-radius: 30px;
  display: flex;
  align-items: center;
  padding: 0px 20px 0px 8px;
  font-family: 'Poppins';
}

.ml-2 {
  margin-left: 10px;
}

.mr-3 {
  margin-right: 15px;
}

.mr-2 {
  margin-right: 10px;
}