@import "../css/variables.module";

.lib-label-font{
  font-size: 10px;
  color: #fff;
  text-align: center;
  margin-bottom: 7px;
}

.library-button {
  @include outlineButtonStyles;

  // background-color: var(--island-bg-color);
  background-color: #071413;

  width: auto;
  height: var(--lg-button-size);

  display: flex;
  align-items: center;
  gap: 0.5rem;

  line-height: 0;

  font-size: 0.75rem;
  letter-spacing: 0.4px;

  svg {
    width: var(--lg-icon-size);
    height: var(--lg-icon-size);
  }

  &__label {
    display: none;

    @media screen and (min-width: 1024px) {
      display: block;
    }
  }
}